html, body  {
  margin: 0px;
  background-color: ghostwhite;
}
div {
  margin: 0px;
}
table {
  width: 100%;
  border-collapse: collapse;
}
.top_table td {
  border-bottom: none;
}
td {
  border: 1px solid black;
  padding: 6px;
  background-color: #ECECF2;
}
.past {
  background-color: #cbcbce;
}
.filled_table {
  padding: 0px;
}
.container {
  max-width: 1400px;
  min-width: 720px;
  margin: 0px auto;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  position: relative;
}
.separated {
  margin: 16px 0px;
}
.left_big {
  width: 68%;
  float: left;
}
.right_small {
  width: 28%;
  float: right;
}
.top_bar {
  padding: 18px;
  background-color: lightsteelblue;
  overflow: hidden;
}
.bar_item {
  display: inline-block;
}
.logo {
  font-size: 48px;
  margin: 0px;
  font-family: system-ui-apple-system BlinkMacSystemFont, Helvetica, sans-serif;
}
.right {
  float: right;
}
.separated_top {
  margin-top: 4px;
}
.title {
  font-size: 32px;
  font-weight: bold;
  margin-top: 8px;
  margin-bottom: 8px;
}
.no_bottom_margin {
  margin-bottom: 0px;
}
.table_title_div {
  display: inline-block;
  padding-top: 8px;
  font-size: 18px;
}
.subtitle {
  margin-top: -4px;
  font-size: 20px;
  font-family: system-ui-apple-system BlinkMacSystemFont, Helvetica, sans-serif;
  color: gray;
}
.heading {
  font-size: 20px;
  font-weight: bold;
  margin-top: 2px;
  margin-bottom: 2px;
}
.text {
  margin-bottom: 0px;
  margin-top: 0px;
}
.indented {
  margin-left: 14px;
}
.gap-20 {
  width:100%;
  height:20px;
}
.radio {
  background-color: #449d44;
  border-color: #449d44;
}
.input,
.react-datepicker__input-container input {
  border: 1px solid black;
  background-color: white;
  margin-bottom: 10px;
  padding: 8px;
  font-size: 18px;
  margin-right: 8px;
}
td .input, td .react-datepicker__input-container input {
  border: none;
  width: 100%;
  height: 100%;
  margin: 0px;
  box-sizing: border-box;
  background-color: white;
  border-collapse: collapse;
}
.table_select {
  border: none;
  background-color: #ECECF2;
  padding: 8px;
  font-size: 18px;
  width: 100%;
  height: 100%;
}
.field {
  display: inline-block;
}
button {
  background-color: black;
  color: white;
  outline: none;
  font-size: 18px;
  padding: 12px 12px;
}
.small {
  font-size: 16px;
  padding: 6px 6px;
}
.table_button_r {
  float: right;
  margin: -6px;
}
.button-wide {
  width:100%;
}
.NOTPROCESSED {
  background-color: lightgray;
}
.DENIED, .false {
  background-color: rgb(252, 85, 47);
}
.PENDINGCREATION {
  background-color: darkorange;
}
.PENDINGFINALIZATION {
  background-color: yellow;
}
.FINALIZED {
  background-color: greenyellow;
}
.SUBMITTED, .true {
  background-color: rgb(0, 171, 0);
}
.saved {
  margin-top: 10px;
  background-color: rgb(0, 171, 0);
  padding: 6px;
  color: white;
}
.updating {
  margin-top: 10px;
  background-color: darkorange;
  padding: 6px;
  color: white;
}